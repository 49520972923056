import { useEffect, useRef, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';

import {
	Maybe,
	Asset,
	FocalPointImage as TFocalPointImage,
	SmallScreenContent,
	ImageAsset as TImageAsset,
	VideoAsset as TVideoAsset,
	Video,
} from '@/types/generated';
import AspectRatioPaddingBox from '@/components/AspectRatioPaddingBox';
import ImageWrapper from '@/components/ImageWrapper';
import ImageAsset from '@/components/ImageAsset';
import VideoAsset from '@/components/VideoAsset';
import { IImage } from '@/components/ImageAsset/ImageAsset';
import { BACKGROUND_IMAGE_QUALITY } from '@/constants';
import { hideControlsForShortVideos } from '@/components/VideoContent/VideoContent.helpers';

import { resolveBackgroundImage, resolveBackgroundVideo } from './BackgroundMedia.helpers';
import {
	backgroundImageStyles,
	videoControlIconStyles,
	getHeroVideoControlsWrapperStyles,
	videoStyles,
} from './BackgroundMedia.styles';

interface IBackgroundMedia {
	contentPosition?: Maybe<string>;
	focalPointImage?: Maybe<TFocalPointImage>;
	isSmallScreen: boolean;
	mediaAspectRatioSmallScreen?: Maybe<string>;
	video?: Maybe<Asset>;
	contentSmallScreen?: Maybe<SmallScreenContent>;
	contentHeight?: number;
	placeholderColor?: string;
	imageAsset?: Maybe<TImageAsset>;
	videoAsset?: Maybe<TVideoAsset>;
	fromHero?: boolean;
	isNoContent?: boolean;
}

function VideoBackground({
	video,
	focalPointImage,
	contentPosition,
	isSmallScreen,
}: Readonly<Pick<IBackgroundMedia, 'video' | 'focalPointImage' | 'contentPosition' | 'isSmallScreen'>>) {
	const [isVideoReady, setIsVideoReady] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const videoRef = useRef<HTMLVideoElement | null>(null);

	useEffect(function handleVideoEvents() {
		const currentVideoRef = videoRef.current;

		function handleVideoReady() {
			setIsVideoReady(true);
		}

		function handleVideoPlaying() {
			setIsPlaying(true);
		}

		function handleVideoPaused() {
			setIsPlaying(false);
		}

		if (currentVideoRef) {
			currentVideoRef.addEventListener('play', handleVideoPlaying);
			currentVideoRef.addEventListener('pause', handleVideoPaused);
			currentVideoRef.addEventListener('oncanplaythrough', handleVideoReady);
		}

		return () => {
			if (currentVideoRef) {
				currentVideoRef.removeEventListener('play', handleVideoPlaying);
				currentVideoRef.removeEventListener('pause', handleVideoPaused);
				currentVideoRef.removeEventListener('oncanplaythrough', handleVideoReady);
			}
		};
	}, []);

	useEffect(
		function handleScrollInOutOfView() {
			const currentVideoRef = videoRef.current;
			const options = { rootMargin: '0px', threshold: [0.25, 0.75] };

			function handleScroll(entries: { isIntersecting: boolean }[]) {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						void currentVideoRef?.play();
					} else {
						void currentVideoRef?.pause();
					}
				});
			}

			const observer = new IntersectionObserver(handleScroll, options);

			observer.observe(currentVideoRef as HTMLVideoElement);

			return () => {
				observer.disconnect();
			};
		},
		[isVideoReady]
	);

	function handleVideoControlClick() {
		if (videoRef.current) {
			if (isPlaying) {
				videoRef.current.pause();
			} else {
				void videoRef.current.play();
			}
		}
	}

	return (
		<>
			<video
				loop
				muted
				playsInline
				ref={videoRef}
				controls={false}
				style={videoStyles}
				disablePictureInPicture
				data-test-id="video_hero"
				src={video?.url as string}
				poster={focalPointImage?.image?.url as string}
				onCanPlayThrough={() => setIsVideoReady(true)}
			/>

			{isVideoReady && hideControlsForShortVideos(videoRef) ? (
				<Box
					data-test-id="group_video_controls"
					sx={getHeroVideoControlsWrapperStyles(contentPosition as string, isSmallScreen)}
				>
					{isPlaying ? (
						<IconButton data-test-id="button_icon_hero_pause" onClick={handleVideoControlClick}>
							<PauseCircleOutlinedIcon sx={videoControlIconStyles} />
						</IconButton>
					) : (
						<IconButton data-test-id="button_icon_hero_play" onClick={handleVideoControlClick}>
							<PlayCircleOutlinedIcon sx={videoControlIconStyles} />
						</IconButton>
					)}
				</Box>
			) : null}
		</>
	);
}

function ImageBackground({ focalPointImage }: Readonly<Pick<IBackgroundMedia, 'focalPointImage'>>) {
	return (
		<ImageWrapper
			style={backgroundImageStyles}
			src={focalPointImage?.image?.url ?? ''}
			alt={focalPointImage?.image?.description ?? ''}
			data-test-id="img_hero_background"
			quality={BACKGROUND_IMAGE_QUALITY}
		/>
	);
}

export default function BackgroundMedia({
	video,
	focalPointImage,
	isSmallScreen,
	contentSmallScreen,
	mediaAspectRatioSmallScreen,
	contentPosition,
	contentHeight = 0,
	placeholderColor,
	imageAsset,
	videoAsset,
	fromHero,
	isNoContent,
}: Readonly<IBackgroundMedia>) {
	const { focalPointImageSmallScreen, videoSmallScreen } = Object(contentSmallScreen) as SmallScreenContent;

	const backgroundVideo = resolveBackgroundVideo({ isSmallScreen, video, videoSmallScreen });
	const backgroundImage = resolveBackgroundImage({ isSmallScreen, focalPointImage, focalPointImageSmallScreen });
	const mediaAspectRatio = isSmallScreen && mediaAspectRatioSmallScreen ? mediaAspectRatioSmallScreen : '';

	if (videoAsset) {
		return (
			<AspectRatioPaddingBox contentfulAspectRatio={mediaAspectRatio} placeholderColor={placeholderColor}>
				<VideoAsset
					desktopVideo={videoAsset.desktopVideo as Video}
					mobileVideo={(videoAsset.mobileVideo as Video) || undefined}
					posterImage={backgroundImage?.image}
					dataTestId="video_asset_hero_background"
					fromHero={fromHero}
					isNoHeroContent={isNoContent}
					mediaAspectRatioSmallScreen={mediaAspectRatio}
				/>
			</AspectRatioPaddingBox>
		);
	}

	if (imageAsset) {
		return (
			<AspectRatioPaddingBox
				aspectRatio="2:1"
				contentfulAspectRatio={mediaAspectRatio}
				placeholderColor={placeholderColor}
			>
				<ImageAsset
					desktopImage={imageAsset?.desktopImage as IImage}
					mobileImage={imageAsset?.mobileImage as IImage}
					imageSx={backgroundImageStyles}
					isBackgroundImage={true}
					dataTestId="img_asset_hero_background"
				/>
			</AspectRatioPaddingBox>
		);
	}

	// TODO - Once all migrated to Image Asset and Video Asset, below code and its references can be removed.

	if (backgroundVideo) {
		return (
			<AspectRatioPaddingBox contentfulAspectRatio={mediaAspectRatio} placeholderColor={placeholderColor}>
				<VideoBackground
					video={backgroundVideo}
					focalPointImage={backgroundImage}
					contentPosition={contentPosition}
					isSmallScreen={isSmallScreen}
				/>
			</AspectRatioPaddingBox>
		);
	}

	if (backgroundImage) {
		return (
			<AspectRatioPaddingBox
				aspectRatio="2:1"
				contentfulAspectRatio={mediaAspectRatio}
				contentHeight={contentHeight}
				placeholderColor={placeholderColor}
			>
				<ImageBackground focalPointImage={backgroundImage} />
			</AspectRatioPaddingBox>
		);
	}

	return null;
}
